import { defineStore } from "pinia";
import { Wallet } from "@/types/wallet/Wallet";
import { walletApi } from "@/api/wallet.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { AppDefaultListItem } from "@/types/common/AppDefaultListItem";
import { CurrencyEnum } from "@/utils/enum/wallet/currencyEnum";

export const YOLLLO_TO_YLT_CONVERSION_RATE = 0.5588;

export const useWalletsStore = defineStore({
  id: "wallets",
  state: () => ({
    wallets: [] as Wallet[],
  }),
  getters: {
    getMaticAddress(): string {
      return (
        this.wallets.find((i) => i.currency === CurrencyEnum.MATIC)?.address ||
        ""
      );
    },
    getWalletsLength(): number {
      return this.wallets.length;
    },
    getWalletsAsDefaultAppList(): AppDefaultListItem[] {
      return this.wallets.map((i) => ({ text: i.currency, value: i.address }));
    },
    getYollloWalletAvailableBalance(): number {
      return (
        (this.wallets.find((i) => i.currency === CurrencyEnum.YOLLLO)
          ?.balance || 0) * YOLLLO_TO_YLT_CONVERSION_RATE
      );
    },
    getYollloWalletLockedBalance(): number {
      return (
        (this.wallets.find((i) => i.currency === CurrencyEnum.YOLLLO)
          ?.lockBalance || 0) * YOLLLO_TO_YLT_CONVERSION_RATE
      );
    },
    getYollloWalletTotalBalance(): number {
      return (
        this.getYollloWalletLockedBalance + this.getYollloWalletAvailableBalance
      );
    },
    getYollloWallet(): Wallet | null {
      return (
        this.wallets.find((i) => i.currency === CurrencyEnum.YOLLLO) || null
      );
    },
  },
  actions: {
    async getWallets() {
      const [error, response] = await walletApi.getWallets();

      if (error) {
        await useExceptionHandler(error);
        return;
      }

      if (response) {
        this.wallets = response;
      }
    },
    updateWallet(wallet: Wallet) {
      const index = this.wallets.findIndex(
        (i) => i.currency === wallet.currency
      );
      this.wallets[index] = wallet;
    },
  },
});
