import { defineStore } from "pinia";

export const useGeneralStore = defineStore({
  id: "general",
  state: () => ({
    error: null as string | null,
    success: null as string | null,
    emailInfo: null as string | null,
    loading: false as boolean,
    showLargePreloader: false,
    isDoomScrollEnabledOnMobile: false as boolean,
  }),
  getters: {
    getError(): string | null {
      return this.error;
    },
    getSuccess(): string | null {
      return this.success;
    },
    getLoading(): boolean {
      return this.loading;
    },
    getEmailInfo(): string | null {
      return this.emailInfo;
    },
    getShowLargePreloader(): boolean {
      return this.showLargePreloader;
    },
    getDoomScrollEnabledOnMobile(): boolean {
      return this.isDoomScrollEnabledOnMobile;
    },
  },
  actions: {
    setShowLargePreloader(payload: boolean) {
      this.showLargePreloader = payload;
    },
    setError(payload: string | null) {
      this.error = payload;
    },
    setSuccess(payload: string | null) {
      this.success = payload;
    },
    setLoading(payload: boolean) {
      this.loading = payload;
    },
    setEmailInfo(payload: string) {
      this.emailInfo = payload;
    },
    setDoomScrollEnabledOnMobile(payload: boolean) {
      this.isDoomScrollEnabledOnMobile = payload;
    },
  },
});
