export enum RoutesNamesEnum {
  LANGUAGE_STACK = "LanguageStack",
  HEADER_STACK = "HeaderStack",
  SIDEBAR_STACK = "SidebarStack",
  HOME_STACK = "HomeStack",
  SEARCH_STACK = "SearchStack",
  USER_PROFILE_STACK = "UserProfileStack",
  USER_ACCOUNT_STACK = "UserAccountStack",
  BALANCES_STACK = "BalancesStack",
  TRANSACTIONS_STACK = "TransactionsStack",
  PROMOTION_STACK = "PromotionStack",
  VERIFICATION_STACK = "VerificationStack",
  IDENTITY_STACK = "IdentityStack",
  CHAT_STACK = "ChatStack",
  CONTENT_STACK = "ContentStack",

  MARKETPLACE_ROUTE_NAME = "Marketplace",
  RECOMMENDATIONS_ROUTE_NAME = "Recommendations",
  SUBSCRIPTIONS_ROUTE_NAME = "Subscriptions",
  YOLLLO_SNIPPETS_ROUTE_NAME = "YOLLLOSnippets",

  SEARCH_USERS_ROUTE_NAME = "SearchUsers",
  SEARCH_CATEGORIES_ROUTE_NAME = "SearchCategories",
  SEARCH_PUBLICATIONS_ROUTE_NAME = "SearchPublications",
  SEARCH_NFTS_ROUTE_NAME = "SearchNfts",
  SEARCH_TAGS_ROUTE_NAME = "SearchTags",

  USER_PROFILE_ALL_CONTENT_ROUTE_NAME = "UserProfileAllContent",
  USER_PROFILE_PUBLICATIONS_CONTENT_ROUTE_NAME = "UserProfilePublicationsContent",
  USER_PROFILE_NFTS_CONTENT_ROUTE_NAME = "UserProfileNftsContent",
  USER_PROFILE_ON_SALE_NFTS_CONTENT_ROUTE_NAME = "UserProfileOnSaleNftsContent",
  USER_PROFILE_OWNED_NFTS_CONTENT_ROUTE_NAME = "UserProfileOwnedNftsContent",
  USER_PROFILE_CREATED_NFTS_CONTENT_ROUTE_NAME = "UserProfileCreatedNftsContent",

  ANALYTICS_ROUTE_NAME = "Analytics",

  ACCOUNT_EDIT_ROUTE_NAME = "AccountEdit",
  ACCOUNT_SUBSCRIPTIONS_ROUTE_NAME = "AccountSubscriptions",
  ACCOUNT_BALANCES_ROUTE_NAME = "AccountBalances",
  ACCOUNT_WITHDRAW_YLT = "AccountWithdrawYLT",
  ACCOUNT_YRM_TX_HISTORY_ROUTE_NAME = "AccountYrmTxHistory",
  ACCOUNT_TX_HISTORY_ROUTE_NAME = "AccountTxHistory",
  ACCOUNT_WITHDRAW_REQUESTS_ROUTE_NAME = "AccountWithdrawRequests",
  ACCOUNT_PAYMENT_HISTORY_ROUTE_NAME = "AccountPaymentHistory",
  ACCOUNT_PROMOTION_ROUTE_NAME = "AccountPromotion",
  ACCOUNT_PROMOTION_PUBLICATIONS_ROUTE_NAME = "AccountPromotionPublications",
  ACCOUNT_PROMOTION_PROFILE_ROUTE_NAME = "AccountPromotionProfile",
  PROFILE_PROMOTION_ROUTE_NAME = "ProfilePromotion",
  PUBLICATION_PROMOTION_ROUTE_NAME = "PublicationPromotion",
  ACCOUNT_SECURITY_ROUTE_NAME = "AccountSecurity",
  ACCOUNT_NOTIFICATIONS_SETTINGS_ROUTE_NAME = "AccountNotification",
  ACCOUNT_PUBLICATION_STATS_ROUTE_NAME = "AccountPublicationStats",
  ACCOUNT_PROFILE_STATS_ROUTE_NAME = "AccountProfileStats",

  ACCOUNT_VERIFICATION_ROUTE_NAME = "AccountVerification",
  ACCOUNT_VERIFICATION_SUCCESS_ROUTE_NAME = "AccountVerificationSuccess",
  ACCOUNT_VERIFICATION_PENDING_ROUTE_NAME = "AccountVerificationPending",
  ACCOUNT_VERIFICATION_FAILED_ROUTE_NAME = "AccountVerificationFailed",

  ACCOUNT_IDENTITY_ROUTE_NAME = "UserAccountIdentity",
  ACCOUNT_IDENTITY_SUCCESS_ROUTE_NAME = "AccountIdentitySuccess",
  ACCOUNT_IDENTITY_PENDING_ROUTE_NAME = "AccountIdentityPending",
  ACCOUNT_IDENTITY_FAILED_ROUTE_NAME = "AccountIdentityFailed",

  ACCOUNT_REFERRAL_PROGRAM_ROUTE_NAME = "AccountReferralProgram",
  ACCOUNT_OFFER_ROUTE_NAME = "AccountOffer",

  CHAT_ROUTE_NAME = "Chat",
  CHAT_DIALOG_ROUTE_NAME = "ChatDialog",
  CREATE_PUBLICATION_ROUTE_NAME = "CreatePublication",
  EDIT_PUBLICATION_ROUTE_NAME = "EditPublication",
  CREATE_NFT_ROUTE_NAME = "CreateNFT",

  SIGN_UP_ROUTE_NAME = "SignUp",
  SIGN_IN_ROUTE_NAME = "SignIn",
  RESTORE_PASSWORD_ROUTE_NAME = "RestorePassword",
  SUPPORT_ROUTE_NAME = "Support",
  CONFIRM_LOGIN_ROUTE_NAME = "ConfirmLogin",

  CONTENT_DESCRIPTION_ROUTE_NAME = "ContentDescription",
  CONTENT_BIDS_ROUTE_NAME = "ContentBids",
  CONTENT_OWNERS_ROUTE_NAME = "ContentOwners",
  CONTENT_HISTORY_ROUTE_NAME = "ContentHistory",
  CONTENT_COMMENTS_ROUTE_NAME = "ContentComments",
}
