<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L15 15M28 28L15 15M15 15L28 2L2 28"
      stroke="url(#paint0_linear_4783_85553)"
      stroke-width="4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4783_85553"
        x1="2"
        y1="28"
        x2="28"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#ED6A32" />
        <stop offset="1" stop-color="#ED3232" />
      </linearGradient>
    </defs>
  </svg>
</template>
