import { defineStore } from "pinia";
import { userInfoAPI } from "@/api/userInfo.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { User } from "@/types/user/User";
import { LargeUserPersonalResponse } from "@/types/user/responses/LargeUserPersonalResponse";
import { useBusinessStore } from "@/stores/business";
import { useIdentityStore } from "@/stores/identity";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    userId: window.localStorage.getItem("userId") as string | null,
    user: null as User | null,
  }),
  getters: {
    getNickname(): string {
      return this.user?.nickname || "";
    },
    getReferralNickname(): string | undefined {
      return (<LargeUserPersonalResponse>this.user)?.referralNickname;
    },
    getIsFullInformationEntered(): boolean {
      return (<LargeUserPersonalResponse>this.user)?.fullInformationEntered;
    },
    getWebsite(): string {
      return (<LargeUserPersonalResponse>this.user)?.website || "";
    },
    getTelegram(): string {
      return (<LargeUserPersonalResponse>this.user)?.telegram || "";
    },
    getWhatsapp(): string {
      return (<LargeUserPersonalResponse>this.user)?.whatsapp || "";
    },
    getBio(): string {
      return (<LargeUserPersonalResponse>this.user)?.description || "";
    },
    getPhone(): string {
      return (<LargeUserPersonalResponse>this.user)?.phone || "";
    },
    getName(): string {
      return (<LargeUserPersonalResponse>this.user)?.name || "";
    },
    getSurname(): string {
      return (<LargeUserPersonalResponse>this.user)?.surname || "";
    },
    getEmail(): string {
      return (<LargeUserPersonalResponse>this.user)?.email || "";
    },
    getFullName(): string {
      return (
        `${this.getName || ""} ${this.getSurname || ""}`.trim() ||
        this.getNickname
      );
    },
    getUserInitials(): string {
      return (
        `${this.getName.split("")[0] || ""}${
          this.getSurname.split("")[0] || ""
        }` || this.getNickname.substring(0, 1)
      );
    },
    getAvatarUrl(): string {
      return this.user?.avatarUrl || "";
    },
    getUserId(): string | null {
      return this.userId;
    },
    getCountry(): string | null {
      return (<LargeUserPersonalResponse>this.user)?.country || null;
    },
    getCity(): string | null {
      return (<LargeUserPersonalResponse>this.user)?.city || null;
    },
    getRegistrationComplete(): boolean {
      return (<LargeUserPersonalResponse>this.user)?.registrationComplete;
    },
    getBirthDate(): number[] {
      return (<LargeUserPersonalResponse>this.user)?.birthDate || [];
    },
    getBirthDateAsString(): string {
      if (!this.getBirthDate.length) return "";
      return `${this.getBirthDate[0]}-${
        this.getBirthDate[1] < 10
          ? `0${this.getBirthDate[1]}`
          : this.getBirthDate[1]
      }-${
        this.getBirthDate[2] < 10
          ? `0${this.getBirthDate[2]}`
          : this.getBirthDate[2]
      }`;
    },
    getIsConfirmedAgreement(): boolean {
      return (<LargeUserPersonalResponse>this.user)?.isConfirmedAgreement;
    },
  },
  actions: {
    setUserId(payload: string) {
      this.userId = payload;
      localStorage.setItem("userId", payload);
    },
    unsetUser() {
      this.user = null;
      localStorage.removeItem("userId");
    },
    setIsConfirmedAgreement(val: boolean) {
      if (!this.user) return;
      (<LargeUserPersonalResponse>this.user).isConfirmedAgreement = val;
    },
    setUser(user: User) {
      this.user = user;
      localStorage.setItem("userId", user.id.toString());
    },
    async getUserInfo() {
      const [error, response] = await userInfoAPI.getUserInfo();
      if (error) {
        await useExceptionHandler(error);
      }

      if (response) {
        this.setUser(response);
        const business = useBusinessStore();
        const identity = useIdentityStore();
        business.setBusinessStatus(
          (response as LargeUserPersonalResponse).businessStatus
        );
        identity.setIdentityStatus(
          (response as LargeUserPersonalResponse).priorityStatus
        );
      }
    },
  },
});
