export enum RoutesPathEnum {
  APP_ROOT_PATH = "/",
  APP_EMPTY_PATH = "",
  MARKETPLACE_PATH = "nft-marketplace",
  RECOMMENDATIONS_PATH = "recommendations-feed",
  RECOMMENDATIONS_ALIAS_PATH = "yolllo-platform-auto-auth",
  YOLLLO_SNIPPETS_PATH = "yolllo-snippets-feed",
  FOLLOWING_RECOMMENDATIONS_PATH = "subscriptions-feed",
  ACCOUNT_SUBSCRIPTIONS_PATH = "subscriptions",
  ACCOUNT_WITHDRAW_REQUESTS_PATH = "withdraw",
  ACCOUNT_PAYMENT_HISTORY_PATH = "payment",
  ACCOUNT_YRM_TX_HISTORY_PATH = "history",
  WITHDRAW_YLT_REQUEST = "withdraw-ylt-request",
  PROFILE_PROMOTION_PATH = "profile",
  PUBLICATION_PROMOTION_PATH = "publication",
  PROFILES_PATH = "profiles",
  PUBLICATIONS_PATH = "publications",
  ACCOUNT_SECURITY_PATH = "security",
  ACCOUNT_NOTIFICATION_SETTINGS_PATH = "notification",
  APP_SUCCESS_PATH = "success",
  APP_PENDING_PATH = "pending",
  APP_FAILED_PATH = "failed",
  ACCOUNT_REFERRAL_PROGRAM_PATH = "referral-program",
  ACCOUNT_OFFER_PATH = "offer",
  CONTENT_DESCRIPTION_PATH = "description",
  CONTENT_BIDS_PATH = "bids",
  CONTENT_OWNERS_PATH = "owners",
  CONTENT_HISTORY_PATH = "history",
  CONTENT_COMMENTS_PATH = "comments",
  CONFIRM_LOGIN_PATH = "confirm-login",
  SIGN_UP_PATH = "sign-up",
  SIGN_IN_PATH = "sign-in",
  SEARCH_USERS_PATH = "users",
  SEARCH_PUBLICATIONS_PATH = "publications",
  SEARCH_CATEGORIES_PATH = "categories",
  SEARCH_TAGS_PATH = "tags",
  SEARCH_NFTS_PATH = "nfts",
  USER_PROFILE_ALL_CONTENT_PATH = "all",
  USER_PROFILE_PUBLICATIONS_CONTENT_PATH = "publications",
  USER_PROFILE_NFTS_CONTENT_PATH = "nfts",
  USER_PROFILE_ON_SALE_NFTS_CONTENT_PATH = "nfts-on-sale",
  USER_PROFILE_OWNED_NFTS_CONTENT_PATH = "nfts-owned",
  USER_PROFILE_CREATED_NFTS_CONTENT_PATH = "nfts-created",
  ANALYTICS_PATH = "analytics",
  EDIT_ACCOUNT_PATH = "edit",
  CHAT_DIALOG_PATH = ":chatId",
  CREATE_PUBLICATION_PATH = "create",
  EDIT_PUBLICATION_PARAMS_PATH = "update/:id",
  EDIT_PUBLICATION_PATH = "update",
  CREATE_NFT_PATH = "create-nft/:webId",
  CONFIRM_REGISTRATION_PATH = "confirm_registration",
  RESTORE_PATH = "restore",
  RESTORE_PASSWORD_PATH = "restore_password",
  CONFIRM_RESTORE_PASSWORD_PATH = "confirm_restore_password",
  SUPPORT_PATH = "support",
  PUBLICATION_STATS_PATH = "stats/:id",

  LANGUAGE_STACK_PATH = "/:lang?",
  SEARCH_STACK_PATH = "search",
  USER_ACCOUNT_STACK_PATH = "account",
  USER_PROFILE_STACK_PATH = "user/:nickname",
  BALANCES_STACK_PATH = "balances",
  TRANSACTIONS_STACK_PATH = "transactions",
  PROMOTION_STACK_PATH = "promotion",
  VERIFICATION_STACK_PATH = "verification",
  IDENTITY_STACK_PATH = "identity",
  CHAT_STACK_PATH = "messages",
  CONTENT_PUBLICATION_STACK_PATH = "assets/:id",
  CONTENT_NFT_STACK_PATH = "assets/nft/:contractAddress/:id",
}
